<template>
  <fw-layout :back-to-enable="false">
    <template #main-content>
      <fw-panel :title="'Mapas de férias'" featured class="mb-5">
        <fw-panel-info
          v-if="!loading && (!userMaps || !userMaps.length)"
          type="basic"
          class="text-center my-5 text-gray-500"
        >
          {{ $t('noMaps') }}
        </fw-panel-info>

        <LoadingPlaceholder v-if="loading" />
        <div v-else class="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-4">
          <template v-for="(item, index) in userMaps">
            <CardUserVacanciesMap
              :key="item.key"
              :index="index"
              :user-map="item"
              :map="maps[item.map_key]"
              :user="users[item.user_key]"
              @open="goToMap(item)"
            />
          </template>
        </div>
      </fw-panel>
      <fw-panel-info label="Data (raw)" debug>
        <json-viewer :value="{ userMaps, users, maps }"></json-viewer>
      </fw-panel-info>
    </template>
  </fw-layout>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import CardUserVacanciesMap from '@/components/cards/CardUserVacanciesMap'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    LoadingPlaceholder,
    CardUserVacanciesMap
  },

  data() {
    return {
      loading: false,
      userMaps: [],
      maps: {},
      users: {},
      latestVersions: null
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    language() {
      return this.$store.state.language
    }
  },

  mounted() {
    this.getUserMaps()
  },

  methods: {
    async getUserMaps() {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getUserMaps({ latest_only: true })
        console.log('getUserMaps :>> ', response)
        this.userMaps = response.user_maps
        this.latestVersions = response.latest_versions
        this.users = response.users
        this.maps = response.maps
      })

      this.loading = false
    },

    goToMap(item) {
      const map = this.maps[item.map_key]
      if (this.latestVersions[map.year]) this.$router.push({ name: 'map', params: { key: `current-${map.year}` } })
      else return this.$router.push({ name: 'map', params: { key: item.key } })
    }
  }
}
</script>

<i18n>
{
  "en": {
    "applyFilters": "Apply filters",
    "noMaps": "Sem tem mapas associados."
  },
  "pt": {
    "applyFilters": "Aplicar filtros",
    "noMaps": "Sem tem mapas associados."
  }
}
</i18n>
